<template>
    <div>
        <CSBreadcrumb />

        <div class="result-panel">
            <div style="padding-top: 20px;margin-left: 30px">
                电表剩余预存金额总计（元）：{{totalRemnant}}
            </div>
            <hr>
            <CSTable >
                <template v-slot:thead>
                    <tr>
                        <th>电表编号</th>
                        <th>关联房间</th>
                        <th>累计使用度数（度）</th>
                        <th>累计消耗金额（元）</th>
                        <th>剩余预存金额（元）</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(meter,index) in meterList" :key="index">
                        <td>{{meter.meterNo}}</td>
                        <td>{{meter.roomName}}</td>
                        <td>{{meter.num}}</td>
                        <td>{{meter.amount}}</td>
                        <td>{{meter.remnant}}</td>
                    </tr>
                </template>
            </CSTable>

        </div>
    </div>
</template>

<script>
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import CSTable from "@/components/common/CSTable";
    import {queryDosageByLesseeIdUrl} from "@/requestUrl";
    export default {
        name: "LesseeElectricityInfo",
        components:{
            CSBreadcrumb,
            CSTable,
        },
        data(){
            return{
                lesseeId:"",
                totalRemnant:"",
                meterList:[]
            }
        },
        created() {
            this.lesseeId = this.$route.query.lesseeId;
            this.totalRemnant = this.$route.query.totalRemnant;
            this.query();
        },
        methods:{
            query(){
                this.$fly
                    .post(queryDosageByLesseeIdUrl, {
                        lesseeId:this.lesseeId,
                        regionCode:this.$vc.getCurrentRegion().code,
                        type:2
                    })
                    .then((res) => {
                        if (res.code != 0){
                            return
                        }
                        this.meterList = res.data;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
